/**
 * Time Page.
 *
 * @module timePageView
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    templatePage = Handlebars.compile(require('./../../templates/pageTime.hbs').default),
    partialTimeSlots = Handlebars.compile(require('./../../templates/partialTimeSlots.hbs').default),
    partialTimeStaff = Handlebars.compile(require('./../../templates/partialTimeStaff.hbs').default),
    modalChooseStaff = Handlebars.compile(require('./../../templates/modalChooseStaff.hbs').default),
    Backbone = require('backbone'),
    BaseView = require('./baseView'),
    Service = require('./../models/serviceModel'),
    Handlebars = require('handlebars'),
    analytics = require('./../helpers/analytics'),
    AvailabilityCollection = require('./../collections/availabilityCollection'),
    TimeSlotsCollection = require('./../collections/timeSlotsCollection'),
    BusinessModel = require('./../models/businessModel'),
    moment = require('moment');

Backbone.$ = $;

require('jquery-ui');
require('jquery-ui-touch-punch');
require('./../helpers/jquerySortableExtension');


var NO_STAFF_SELECTED = BusinessModel.NO_STAFF_SELECTED;
var ANY_STAFF_PERSON = BusinessModel.ANY_STAFF_PERSON;


//required this way (with .js) because currently bootstrap-datepicker is not commonjs compatible
//require('bootstrap-datepicker/js/bootstrap-datepicker.js');

module.exports = BaseView.extend({
    //please note these are essentially static variables
    template: templatePage,
    page: 'time',
    pageTitle: 'Choose a day and time',
    availabilityCollection: {},
    timeSlotsCollection: {},
    staffCollection: false,
    dayPromise: undefined,
    timeRedoError: false,

    events: {
        /**
         * show a modal with a list of staff memebers
         * @param event
         */
        'click .summaryWhoName': function (event) {
            var $target = $(event.currentTarget);
            var serviceId = $target.data('serviceId');
            var isPackageItem = $target.data('service-type') === 'packageItem';
            this.renderStaffModal(serviceId, $target.data('staffId'), $target.data('serviceType'), isPackageItem);
        },
        /**
         * click new staff in staff modal
         *
         * @param event
         */
        'click .list-group a': function (event) {
            //disable the continue button while we fetch the slot info and update the calendar
            $('#btnContinue').addClass('disabled').addClass('btn-default').removeClass('btn-success');
            //hide the timeslots part because we are going to be refreshing the slots
            $('#timeslots').hide();

            //grab the data off the selected staff
            var selectedStaffData = $(event.currentTarget).data();

            this.updateServiceStaff(selectedStaffData);

            this.resetAvailability();

            analytics.trackGAEvent(false, 'Bookings', 'Specific staff member chosen');

            //trigger to update_staff event so url gets updated and calendar gets refreshed
            this.trigger('service:update_staff', selectedStaffData.serviceId, selectedStaffData.staffId, selectedStaffData.serviceType);
        },
        'click #upToDays': function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $(".mainHeading:eq(1)").offset().top
            }, 300);
            $('#upToDays').hide();
        },
        /**
         * select a timeslot
         *
         * @param event
         */
        'change #timeslots input[type="radio"]': function (event) {
            var $picker = $('#datePicker');
            var date = $picker.datepicker("getDate");
            var formattedDate = moment(date).format('YYYY-MM-DD');
            var time = $(event.currentTarget).data('time');

            formattedDate = formattedDate + " " + time;


            analytics.trackGAEvent(false, 'Bookings', 'Time selected');
            this.trigger('time', formattedDate);

            $('#timeslots .selected').removeClass('selected');
            $(event.currentTarget).closest('label').addClass('selected');
            $('#btnContinue').removeClass('btn-default disabled').addClass('btn-success');
        },
        /**
         * click to proceed to time page.
         *
         * @param e
         */
        'click .btn-success': function (e) {
            var date = moment($('#datePicker').datepicker("getDate")).format('YYYY-MM-DD');

            /* There may not be a selected staff member, if there was only one capable and they are auto-selected,
             * however, there must be a time selected in order for the button to be clickable.
             */
            var selectedTimes = $('div.dayTimeSummary .selected input');
            var selectedStaff = $('div#summaryWrap .selected input');
            var time = selectedTimes[0].dataset.time;
            if (selectedStaff.length > 0) {
                selectedStaff[0].dataset.staff = this.stateModel.getFirstServiceStaffKid();
            }

            var slots = this.availabilityCollection.get(date);
            var slotDateTime = date + " " + time + ":00";

            var slot = slots.getSlotDetails(slotDateTime);
            this.stateModel.setRequestedBookingSlot(slot);
            var to = this.stateModel.buildStateHashUrl('#time');
            $('body').trigger('route:replace_only', to);
        },

        /**
         * select a staff member
         *
         * @param e
         */
        'change .staffSelector': function (e) {
            var element = e.currentTarget;
            if (!element.selected) {
                this.updateSingleStaffSelection(element.id, element.name);
                this.resetAvailability();
            } else {
                //reset
                this.updateSingleStaffSelection(NO_STAFF_SELECTED, null);
            }

            //we do this to make sure the staff selection changed are reflected in the URL and trigger a re-render
            this.stateModel.updateURLHash();
        }
    },

    updateServiceStaff: function ($clickedTarget) {
        _.each($('.summaryWhoName'), function (value, key) {
            if ($(value).data('serviceId') === $clickedTarget.serviceId && $(value).data('serviceType') === $clickedTarget.serviceType) {
                $(value).data('staffId', $clickedTarget.staffId);

                var name = this.staffCollection.get($clickedTarget.staffId).get('formattedName');
                $(value).html(name + '<i class="fa fa-caret-down"></i>');
            }
        }, this);
    },

    shouldAllocateStaffToServices: function (staffKid, capableStaffKids) {
        if (staffKid === ANY_STAFF_PERSON) {
            return true;
        }

        if (staffKid === NO_STAFF_SELECTED) {
            return false;
        }

        return capableStaffKids.some(function(capableStaffPerson) {
            return String(capableStaffPerson) === String(staffKid)
        });
    },

    /**
     * Assigns that staff member's name and id to every service/packages, selects the one that the user clicked,
     * and saves the staff name and id to the selected services (stateModel.services) and package items (stateModel.packageDetails)
     * on the state model.
     *
     * @param staffId (string)
     * @param staffName (string)
     */
    updateSingleStaffSelection: function (staffId, staffName) {

        /** @type StateService **/
        var selectedServices = this.stateModel.get('services');

        selectedServices.each(function (service) {

            if (service.isAPackage()) {
               this.allocateStaffToPackageItems(service, staffId, staffName);
            }

            service.set({staffId: staffId, staffName: staffName});

        }.bind(this));

        this.updateStaffSelectors(staffId);
    },

    allocateStaffToPackageItems: function (packageService, staffId, staffName) {
        //I don't know why we are doing this, I just refactored this code
        packageService = this.servicesAll.get(packageService.get("id"));

        /** @type Array **/
        var servicesInPackage = packageService.get("services");

        servicesInPackage.forEach(function (service){
            this.stateModel.get('packageDetails').add({id: service.itemId, type: "packageItem", staffId: staffId, staffName: staffName}, {merge: true});
        }.bind(this));
    },

    updateStaffSelectors: function (staffId) {
        this.$el.find(".staffSelector").each(function (index, el) {

            var grandparent = $('#' + el.id).closest('label.clickableLabel');

            if (el.id === staffId) {
                el.checked = true;
                grandparent.addClass("selected");
            } else {
                el.checked = false;
                grandparent.removeClass("selected");
            }
        });
    },

    /**
     * Performs a check to make sure that the date and time chooser should be displayed (ie. that all services and
     * packages have staff members assigned to them).
     */
    checkedCalendarRender: function () {
        this.handleTimeSelectionVisibility();
        var chosenTime = this.stateModel.get('time') || null;
        this.renderCalendar(chosenTime);
    },

    handleTimeSelectionVisibility: function () {
        if (this.shouldShowTimeSelection()) {
            this.showTimeSelection();
        } else {
            this.hideTimeSelection();
        }
    },

    shouldShowTimeSelection: function () {
        return !(this.stateModel.hasServicesWithNoStaff() || this.hasPackagesWithNoStaff());
    },

    showTimeSelection: function () {
        return this.$timeSelection().removeClass('dayTimeHidden');
    },

    hideTimeSelection: function () {
        return this.$timeSelection().addClass('dayTimeHidden');
    },

    timeSelectionIsVisible: function () {
        return !this.$timeSelection().hasClass("dayTimeHidden");
    },

    /**
     * @returns {$}
     */
    $timeSelection: function () {
        return this.$el.find(".dayTimeSummary");
    },

    initialize: function (options) {
        //call parent constructor
        BaseView.prototype.initialize(options);
        this.timeSlotsCollection = new TimeSlotsCollection(null, {businessModel: this.businessModel});

        this.availabilityCollection = new AvailabilityCollection(null, {
            packagesCollection: this.packagesCollection,
            stateModel: this.stateModel
        });

        if (options.packagesCollection) {
            this.packagesCollection = options.packagesCollection;
        }
        if (options.servicesAll) {
            this.servicesAll = options.servicesAll;
        }
        if (options.staffCollection) {
            this.staffCollection = options.staffCollection;
        }
        if (options.timeRedoError) {
            this.timeRedoError = options.timeRedoError;
        }
        this.noBookingAvailable = options.noBookingAvailable;


        _.bindAll(this, 'renderSlots');
    },
    /**
     * This handles resetting the availabilityCollection but making sure the request dates match what the calendar is
     * showing.
     * This is pretty hacky
     */
    resetAvailability: function(){
        var datePickerData = $('#datePicker').data('datepicker');
        if(datePickerData){
            //if we are changing staff we want to clear the availability but keep the month they have selected
            //usually this is handled by the time param in the url, but that is only set once they actually select a
            //date
            this.availabilityCollection.reset();
            this.availabilityCollection.setMonth(datePickerData.drawYear + '-' + (datePickerData.drawMonth+1) + '-01');
        } else {
            //if the date picker doesn't exist yet just reset everything, including the calendar selected date
            this.availabilityCollection.resetDays()
        }
    },
    /**
     * re-render the calendar but preserve the selected date/time
     * Shouldn't need to use this anymore
     */
    renderCalendarPreserveDateTime: function () {
        var dateString;
        var $selectedDay = $('.ui-datepicker-current-day');
        var $datePicker = $('#datePicker');

        if (this.timeSelectionIsVisible() && !this.stateModel.hasServicesWithNoStaff(this.servicesAll) && !this.hasPackagesWithNoStaff()) {

            if (!this.availabilityCollection.monthLoaded($datePicker.data('datepicker').drawMonth)) {
                // console.log('setting calendar to current date');
                $datePicker.datepicker('setDate', $datePicker.datepicker('getDate'));
            }

            if ($selectedDay.find('.ui-state-active').length) {
                var year = $selectedDay.data('year');
                var month = parseInt($selectedDay.data('month')) + 1;//zero based
                var day = parseInt($selectedDay.text());
                var time = $('input[name=slot]:checked').val();

                if ($('input[name=slot]:checked') && $('input[name=slot]:checked').attr('id')) {
                    if ($('input[name=slot]:checked').attr('id').toString().substr(0, 1) === 'm') {
                        time = time + " AM";
                    } else {
                        time = time + " PM";
                    }
                    dateString = moment(year + "-" + month + "-" + day + " " + time, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD HH:mm');
                } else {
                    dateString = year + "-" + month + "-" + day;
                }

                //change format
                this.renderCalendar(dateString);
            } else {
                this.renderCalendar();
            }
        }

    },
    renderStaffModal: function (serviceId, currentStaffId, serviceType, isPackageItem) {
        var data = {};
        data.capableStaff = this.staffCollection.servicesByStaff[serviceId];

        data = this.handleAnyoneValues(data);

        data.serviceId = serviceId;
        data.currentStaffId = currentStaffId.toString();
        data.serviceType = serviceType;
        data.prices_enabled = (this.businessModel.get('prices_enabled') && !isPackageItem);
        data.durations_enabled = (this.businessModel.get('durations_enabled') && !isPackageItem);
        data.rhs_enabled = data.prices_enabled || data.durations_enabled;
        this.$el.find('#chooseStaff').html(modalChooseStaff(data));
    },

    handleAnyoneValues: function (data) {
        var currencySymbol = this.stateModel.get('businessModel').get('currency_symbol');

        var maxPrice = -1, minPrice = 9007199254740990; //js max safe int
        var maxDuration = -1, minDuration = 9007199254740990; //js max safe int

        // TODO, remove this ugly.
        for(var capable_index in data.capableStaff) {
            var price = parseFloat(data.capableStaff[capable_index].price),
                duration = parseFloat(data.capableStaff[capable_index].formattedDuration);

            if(price > maxPrice) {
                maxPrice = price;
            }
            if(price < minPrice) {
                minPrice = price;
            }
            if(duration > maxDuration) {
                maxDuration = duration;
            }
            if(duration < minDuration) {
                minDuration = duration;
            }
        }

        data.anyonePrice = minPrice === maxPrice ? currencySymbol + minPrice : currencySymbol + minPrice + " to " + currencySymbol + maxPrice;
        data.anyoneDuration = minDuration === maxDuration ? maxDuration : minDuration + " to " + maxDuration;

        return data;
    },
    /**
     * make draggable services/package order
     */
    sortServices: function () {
        var that = this;

        $("#summaryWhoList").sortable({
            placeholder: {
                element: function (currentItem) {
                    return $("<div id='dragHalp'>&nbsp;</div>")[0];
                },
                update: function (container, p) {
                    return;
                }
            },
            containment: '#summaryWrap',
            handle: '.servRowHandle',
            axis: 'y',
            tolerance: 'pointer',
            forcePlaceholderSize: true,
            start: function (evt, ui) {
                ui.placeholder.height(ui.item.height() - 2); //border
            },
            stop: function (event, ui) {

                function getGetOrdinal(n) {
                    var s = ['th', 'st', 'nd', 'rd'],
                        v = n % 100;
                    return n + (s[(v - 20) % 10] || s[v] || s[0]);
                }

                $('.servWhoRow').each(function (key, value) {
                    $(this).find('.ordertxt').html(getGetOrdinal(key + 1));
                });

                that.resetAvailability();
                $('#timeslots').hide();
                $('.ui-state-active').removeClass('ui-state-active').parent('td').removeClass('ui-datepicker-current-day');
                $('#btnContinue').addClass('disabled').addClass('btn-default').removeClass('btn-success');

                var newOrder = _.map($('.servWhoRow'), function (item, k) {
                    var packageDetails;
                    var $target = $(item);
                    if ($target.data('serviceType') === 'package') {

                        packageDetails = _.map($target.find('.packageWhoContents li a'), function (item) {
                            var $target = $(item);
                            return [$target.data('serviceId'), $target.data('serviceType'), $target.data('staffId')];
                        });
                        return [$target.data('serviceId'), $target.data('serviceType'), packageDetails];
                    } else {
                        return [$target.data('serviceId'), $target.data('serviceType'), $target.data('staffId')];
                    }
                });
                analytics.trackGAEvent(false, 'Bookings', 'Services re-ordered');
                that.trigger('service:update_position', newOrder);
            }
        });
        $("#summaryWhoList").disableSelection();
    },

    /**
     *
     */
    getFormattedAnyoneRowPrice: function (staffPriceMap, capableIds, currency) {
        // Get the cheapest and most expensive staff prices for the "anyone" option.
        var maxPrice = -1, minPrice = 9007199254740990; //js max safe int
        for (var staffKid in staffPriceMap) {
            if (capableIds.indexOf(staffKid) >= 0) {
                var price = Math.round(parseFloat(staffPriceMap[staffKid])*100)/100; // This rounds to 2dp if there are cents, otherwise an int
                if (price > maxPrice) {
                    maxPrice = price;
                }
                if (price < minPrice) {
                    minPrice = price;
                }
            }
        }

        maxPrice = this.toTwoDecimalPlaceIfNeeded(maxPrice);
        minPrice = this.toTwoDecimalPlaceIfNeeded(minPrice);

        /* This is for the 'anyone' entry. We don't want to print a max and min price if they're the same,
         * only if they're different.
         */
        return minPrice === maxPrice ? currency + minPrice : currency + minPrice + " to " + currency + maxPrice;
    },

    getFormattedAnyoneRowDuration: function (staffDurationMap, capableIds) {
        var maxDuration = -1, minDuration = 9007199254740990; //js max safe int

        for (var staffKid in staffDurationMap) {
            if (capableIds.indexOf(staffKid) >= 0) {
                var duration = parseFloat(staffDurationMap[staffKid]); // This rounds to 2dp if there are cents, otherwise an int
                if (duration > maxDuration) {
                    maxDuration = duration;
                }
                if (duration < minDuration) {
                    minDuration = duration;
                }
            }
        }

        return minDuration === maxDuration ? maxDuration : minDuration + " to " + maxDuration;
    },

    // Cheeky code to format the number to 2dp if value is not integer.
    toTwoDecimalPlaceIfNeeded: function(value) {
        if(value.toFixed(2) > Math.floor(value)) {
            value = value.toFixed(2);
        }

        return value;
    },

    /**
     * render services/packages and chosen staff members.
     */
    renderBookedServices: function () {
        var that = this,
            wtfServiceIds = this.stateModel.wtfServiceIds(this.servicesAll),
            serviceIds = this.stateModel.getServiceIds(this.servicesAll);

        this.staffCapabilityPromise = this.staffCollection.getCapability(serviceIds);

        $.when(this.staffCapabilityPromise, this.stateModel).done(function (staffCapabilityPromise) {
            var data = {}, staffListOfLists = [], staffDurationMap = {}, staffPriceMap = {}, capableStaff = [],
                oneStaffOnly = that.businessModel.get('one_staff_only') === "1",
                defaultToAnyone = that.businessModel.get('default_to_anyone') === "1",
                showPrices = that.businessModel.get('prices_enabled'),
                showDurations = that.businessModel.get('durations_enabled'),
                multipleServices = serviceIds.length > 1,
                showStaffSelector = true,
                allStaffAreTheSame = true,
                isRebooking = that.stateModel.is_rebooking === true, // false for undefined
                initialAnyoneStaff = that.staffCollection.createAnyoneStaff(),
                staff,
                currencySymbol = that.businessModel.get('currency_symbol');

            /* Get all of the staff capable of performing each service and make a super list containing them all. At
             * the same time (to avoid unnecessary looping) add the staff's price for that service to the price map.
             */
            $.each(wtfServiceIds, function (index, servInfo) {
                var service = staffCapabilityPromise[0][servInfo.service_id];
                var staffCapableOfThisService = [];
                $.each(service, function (index, person) {
                    var id = person.staffId;
                    staffCapableOfThisService.push(id);
                    if(showPrices){
                        staffPriceMap[id] = staffPriceMap.hasOwnProperty(id) ? staffPriceMap[id] : 0; // Make sure the staff is in the price map
                        if(!servInfo.packageId) {
                            staffPriceMap[id] = staffPriceMap.hasOwnProperty(id) ? staffPriceMap[id] + parseFloat(person.price) : parseFloat(person.price);
                        }
                    }
                    if(showDurations) {
                        staffDurationMap[id] = staffDurationMap.hasOwnProperty(id) ? staffDurationMap[id] : 0; // Make sure the staff is in the price map
                        if(!servInfo.packageId) {
                            staffDurationMap[id] = staffDurationMap.hasOwnProperty(id) ? staffDurationMap[id] + parseFloat(person.formattedDuration) : parseFloat(person.formattedDuration);
                        }
                    }
                });
                staffListOfLists.push(staffCapableOfThisService);
            });

            that.stateModel.get("services").each(function(base_item) {
                if (base_item.get('type') === 'package') {
                    var packageInformation  = that.servicesAll.get(base_item.get("id"));

                    _.each(staffPriceMap, function(staff_price, staff_id) {
                        staffPriceMap[staff_id] = staffPriceMap.hasOwnProperty(staff_id)
                            ? staffPriceMap[staff_id] + parseFloat(packageInformation.get("price"))
                            : parseFloat(packageInformation.get("price"));
                    });

                    _.each(staffDurationMap, function(staff_duration, staff_id) {
                        staffDurationMap[staff_id] = staffDurationMap.hasOwnProperty(staff_id)
                            ? staffDurationMap[staff_id] + parseFloat(packageInformation.get("duration"))
                            : parseFloat(packageInformation.get("duration"));
                    });
                }
            });

            /* Now get all the staff thats are capable of doing every one of the services, and get their price for doing
             * all the services. We're basically making a super-package.
             */
            var capableStaffKids = that.getElementsInAllLists(staffListOfLists);

            initialAnyoneStaff.formattedPrice = that.getFormattedAnyoneRowPrice(staffPriceMap, capableStaffKids, currencySymbol);
            initialAnyoneStaff.formattedDuration = that.getFormattedAnyoneRowDuration(staffDurationMap, capableStaffKids);

            // Build a performing all services staff list (IE, the staff list used by the "one staff only" feature.
            var staffListOfFirstService = staffCapabilityPromise[0][serviceIds[0]];
            _.each(capableStaffKids, function (capableStaffMemberId) {
                _.each(staffListOfFirstService, function (staff) {
                    if (staff.staffId === capableStaffMemberId) {
                        var fullyCapableStaff = _.clone(staff);
                        if (showPrices) {
                            //Assign the price for this staff member to perform ALL services.
                            fullyCapableStaff.formattedPrice = "" + currencySymbol + that.toTwoDecimalPlaceIfNeeded(staffPriceMap[capableStaffMemberId]);
                        }
                        if(showDurations) {
                            fullyCapableStaff.formattedDuration = staffDurationMap[capableStaffMemberId];
                        }
                        capableStaff.push(fullyCapableStaff);
                    }
                });
            });

            that.staffCollection.sortStaff(capableStaff);

            capableStaff.unshift(initialAnyoneStaff);

            var onlyOneCapableStaff = capableStaff.length === 2 || capableStaff.length === 1;

            // If there is only the 'anyone' option, or nothing at all, then there is no staff capable of all services,
            // so pretend that's okay and find different staff.
            if (capableStaff.length === 0 || capableStaff.length === 1) {
                oneStaffOnly = false;
            }

            var preSelected = null;

            if (oneStaffOnly) {
                var checkedId = that.stateModel.getFirstServiceStaffKid();
                var checkedName = that.stateModel.getFirstServiceStaffName(that.staffCollection);

                staff = {staffId: checkedId, formattedName: checkedName};
                if (isRebooking) {
                    var service = that.stateModel.get('services').models[0];
                    var theId = service.attributes.staffId;
                    var returned = $.grep(capableStaff, function (e) {
                        return e.staffId === theId;
                    });
                    if (returned.length > 0) {
                        // If the original rebooking staff member still exists, go with them.
                        staff = returned[0];
                        defaultToAnyone = false;
                    }
                }
                if (defaultToAnyone) {
                    if (onlyOneCapableStaff) { // '2' is the 'anyone' option and one staff member. So really only one capable staff.
                        showStaffSelector = false;
                    } else {
                        if(showPrices) {
                            capableStaff[0].formattedPrice = that.getFormattedAnyoneRowPrice(staffPriceMap, capableStaffKids, currencySymbol);
                            capableStaff[0].formattedDuration = that.getFormattedAnyoneRowDuration(staffDurationMap, capableStaffKids);
                        }
                    }

                    that.showTimeSelection();
                } else {
                    // If someone is selected, show them, otherwise make 'nobody' selected, and give a single staff selector. no calendar
                    if (onlyOneCapableStaff) { // '2' is the 'anyone' option and one staff member. So really only one capable staff.
                        if (capableStaff.length === 2) {
                            staff = capableStaff[1];
                        } else {
                            staff = null;
                        } // TODO Ben. Delete this line.

                        if(staff) {
                            preSelected = staff;

                            showStaffSelector = false;
                        }
                    }

                    if (that.shouldAllocateStaffToServices(staff.staffId, capableStaffKids)) {
                        that.updateSingleStaffSelection(staff.staffId, staff.formattedName);
                    }
                    that.showTimeSelection();
                }
            } else if (defaultToAnyone && !isRebooking) {
                //make anyone selected for all services. calendar shows
                if (onlyOneCapableStaff && !multipleServices) {
                    //Since there are only two staff in the list, and one of them is the 'anyone' option,
                    //there is only one actual staff that can do the service. For a single service, auto select them.
                    if(capableStaff.length === 2){
                        staff = capableStaff[1];
                    } else {
                        staff = null;
                    }

                    if(staff) {
                        preSelected = staff;
                        showStaffSelector = false;
                    }
                } else {
                    allStaffAreTheSame = false;
                }
                that.showTimeSelection();
            } else {
                //all services have the 'please select' option selected. no calendar
                if (multipleServices) {
                    allStaffAreTheSame = false;
                }

                that.showTimeSelection();
            }

            data.oneStaffOnly = oneStaffOnly;
            data.showStaffSelector = showStaffSelector;
            data.showPrices = showPrices;
            data.multipleServices = multipleServices;
            data.serviceDetails = that.stateModel.getServiceDetails(that.servicesAll, that.staffCollection); //refresh service list
            data.staffDetails = capableStaff;
            data.preSelected = preSelected;
            data.business = that.stateModel.get('businessModel').toJSON();
            that.$el.find('#summaryWrap').html(partialTimeStaff(data));
            if (allStaffAreTheSame) {
                that.updateSingleStaffSelection(that.stateModel.getFirstServiceStaffKid(), that.stateModel.getFirstServiceStaffName(that.staffCollection));
            }
            if (data.multipleServices) {
                that.sortServices();
            }
            that.checkedCalendarRender();
        });
    },

    /**
     * Iterates through a 2d array and pulls out all of the elements that are in all of the lists.
     * It does this like so:
     *      Get an initial array to check everything against
     *      Compare the rest of the arrays one by one
     *      If an entry is found in the array currently being checked, and the base one, add it to a 'found' array.
     *      Once all the elements have been checked, the 'found' array becomes the base array and we move onto the next list.
     *      At the end, we should have a list of elements in all lists.
     *
     * @param listOfLists
     */
    getElementsInAllLists: function (listOfLists) {
        //Pop a list off to compare the first entry against.
        var initialList = listOfLists[0];
        //Start from index 1 cause we already have the first one.
        for (var i = 1; i < listOfLists.length; i++) {
            var listOfEntriesInBoth = [];
            for (var item in listOfLists[i]) {
                if (initialList.indexOf(listOfLists[i][item]) > -1) {
                    //It's in the list, add it
                    listOfEntriesInBoth.push(listOfLists[i][item]);
                }
            }
            initialList = listOfEntriesInBoth;
        }
        return initialList.sort();
    },
    /**
     * pass true to mark the calendar as currently loading.
     *
     * @param {string} status
     */
    calendarLoadingStatus: function (status) {
        var $datePicker = $('#datePicker');
        if (status) {
            $datePicker.addClass('dp-loading');
        } else {
            //calendar is loaded
            $datePicker.removeClass('dp-loading');
        }
    },

    removeLoadingAndRender: function ($datePicker) {
        this.calendarLoadingStatus(false);
        //console.log("calendar loaded from ajax");
        if ($datePicker.data('renderSlots')) {
            this.renderSlots(moment($datePicker.data('renderSlots'), 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'), $datePicker.data('renderSlots'));
            $datePicker.removeData('renderSlots');
        }
    },
    /**
     *
     * @param dateString format 'YYYY-MM-DD HH:mm'
     */
    renderCalendar: function (dateString) {
        var month;
        var that = this;

        if (this.timeSelectionIsVisible() && !this.stateModel.hasServicesWithNoStaff() && !this.hasPackagesWithNoStaff()) {
            if (typeof dateString === 'string' && dateString !== '') {
                month = moment(dateString, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
                this.availabilityCollection.setMonth(month);
            }

            var availabilityPromise = this.availabilityCollection.fetch();


            var $datePicker = $('#datePicker');

            //show a disabled datepicker while the main datepicker loads
            if ($datePicker.hasClass('hasDatepicker') === false) {
                $datePicker.addClass('firstLoad');
                $datePicker.datepicker("destroy");
                $datePicker.datepicker({
                    minDate: 0,
                    maxDate: 0,
                    beforeShowDay: function (date) {
                        return false;
                    }
                });
            }
            this.calendarLoadingStatus(true);

            $.when(availabilityPromise, this.availabilityCollection).done(
                function (availabilityPromise, availabilityCollection) {
                    var $datePicker = $('#datePicker');

                    if ($datePicker.hasClass('hasDatepicker') === false || $datePicker.hasClass('firstLoad')) {
                        $datePicker.removeClass('firstLoad');
                        $datePicker.datepicker("destroy");

                        $datePicker.datepicker({
                            minDate: -1,
                            dateFormat: "yy-mm-dd",
                            maxDate: "+12m",
                            /**
                             * A function that takes a date as a parameter and must return an array with:
                             *
                             [0]: true/false indicating whether or not this date is selectable
                             [1]: a CSS class name to add to the date's cell or "" for the default presentation
                             [2]: an optional popup tooltip for this date

                             * @param date
                             * @returns {*}
                             */
                            beforeShowDay: function (date) {
                                var formattedDate = moment(date).format('YYYY-MM-DD');
                                var response = [false, '', ''];
                                var dateAvailable = availabilityCollection.get(formattedDate);

                                if (dateAvailable && dateAvailable.checkEnabled()) {
                                    response[0] = true;
                                }
                                return response;
                            },
                            /**
                             * when changing through dates on the calendar try and get some
                             * data in advance so there is minimal delay for the user.
                             * If the user gets to a month where we are still waiting from a response from
                             * the server then mark the calendar as loading (using class) and wait for the
                             * response before refreshing the calendar with the data.
                             *
                             * @param year
                             * @param month
                             */
                            onChangeMonthYear: function (year, month) {
                                var date = year + "-" + month + "-01",
                                    //month is not zero based, momentjs is so remove 1
                                    zeroedMonth = month - 1;

                                that.calendarLoadingStatus(true);

                                //console.log('getNextFutureMonth month', date);

                                availabilityCollection.getNextFutureMonth(date, function (range) {
                                    var $datePicker = $('#datePicker');

                                    if (!_.size($datePicker)) {
                                        //customer has changed month, then clicked continue, datepicker no longer visible
                                        return false;
                                    }

                                    //this is undocumented behavior so may change without warning..
                                    var shownMonth = $datePicker.data('datepicker').drawMonth;

                                    //get dates -> extract month -> make uniq
                                    var monthsUpdated = _.uniq(_.map(_.keys(range.days), function (days) {
                                        return moment(days).get('month');
                                    }));
                                    //console.log('response:', 'date', shownMonth, ' month ', shownMonth);
                                    //console.log('monthsupdated', monthsUpdated);

                                    //if currently selected month is in response then refresh picker
                                    if (monthsUpdated.indexOf(shownMonth) !== -1) {
                                        $datePicker.datepicker("refresh");
                                        that.removeLoadingAndRender($datePicker);
                                    }
                                });

                                if (availabilityCollection.monthLoaded(zeroedMonth)) {
                                    that.removeLoadingAndRender($datePicker);
                                }
                            },
                            //   defaultDate: "-1m", //old date
                            onSelect: that.renderSlots
                        });
                        $datePicker.find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');

                        //skip to the next month if near end of current month AND no slots left this month
                        var eom = moment().endOf('month'),
                            today = moment().today,
                            diff = eom.diff(today, 'days');

                        //console.log('days till end of month: ' + diff);
                        if (diff <= 7 && $datePicker.find('a.ui-state-default').length === 0) {
                            console.log('skiping to next month');
                            $datePicker.datepicker('setDate', 'c+1m');
                        }

                    } else {
                        $datePicker.datepicker('refresh');
                    }

                    //because of the promise, at this point we should always have data
                    that.calendarLoadingStatus(false);

                    if (typeof dateString === 'string') {
                        var momentDateTimeSelected = moment(dateString, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
                        $datePicker.datepicker("setDate", momentDateTimeSelected);

                        //  var currentCalDate = moment($datePicker.datepicker("getDate")).format('MMMM YYYY');

                        that.renderSlots(momentDateTimeSelected, dateString);
                    } else {
                        $datePicker.find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
                    }
                });
        }
    },

    renderSlots: function (dateString, clickDate) {
        //we don't want it if it is jquery thing
        if (typeof clickDate !== 'string') {
            clickDate = false;
            var $picker = $('#datePicker');
            var dpDate = $picker.datepicker("getDate");
            var formattedDate = moment(dpDate).format('YYYY-MM-DD');

            analytics.trackGAEvent(false, 'Bookings', 'Date selected');
            this.trigger('time', formattedDate);
        }



        var that = this;
        var date = moment(dateString, 'YYYY-MM-DD');
        var dayPromise;
        var dayModel = this.availabilityCollection.get(date.format('YYYY-MM-DD'));

        $('#btnContinue').addClass('btn-default disabled').removeClass('btn-success');

        $('#timeslotsWrap').html('<i class="fa fa-refresh fa-spin"></i>').addClass('thinking');

        if (dayModel.get('slots').length === 0) {
            this.dayPromise = dayPromise = dayModel.fetch();
        } else {
            this.dayPromise = dayPromise = dayModel;
        }

        var clickDatePromise = $.Deferred();
        clickDatePromise.resolve(clickDate);

        $.when(dayPromise, clickDatePromise).done(function (dayData, dateTime) {
            if (dayPromise !== that.dayPromise) {
                return;
            }
            var data = {};
            var date = moment($('#datePicker').datepicker('getDate'));
            var formatedDate = date.format('ddd D MMM YYYY');
            var dayModel = that.availabilityCollection.get(date.format('YYYY-MM-DD'));
            var returnDate;

            if (typeof dayData.getServices === 'function') {
                returnDate = dayData.get('date');
            } else {
                returnDate = dayData[0].date;
            }


            var dayOfWeek = moment(returnDate).format('ddd').toLowerCase();
            var timeSlots = that.timeSlotsCollection;

            //synchronous
            that.timeSlotsCollection.fetch(dayOfWeek);

            timeSlots.set(dayModel ? dayModel.getAvailableSlots() : [], {remove: false});
            timeSlots.tidy();

            data.slots = timeSlots.slots;
            data.cols = timeSlots.cols;
            data.slotsHeading = formatedDate;

            that.$el.find('#timeslotsWrap').html(partialTimeSlots(data));


            $('#timeslots').show(0, function () {
                //console.log('clickDate', clickDate);
                //scroll down to the (wrapped) timeslots if on -xs resolution
                if ($(window).width() < 768 && !clickDate) {
                    $('html, body').animate({
                        scrollTop: $('#timeslots').offset().top
                    }, 600);
                }
            });

            $('#upToDays').show();
            $('#slotsDateHeading').show().find('.dateText').html(formatedDate);
            $('#timeslotsWrap').removeClass('thinking');

            var clickTime = moment(clickDate, 'YYYY-MM-DD HH:mm').format('Hmm');

            /**
             * if there is no time component to the clickDate string then the above is expected
             * to return "000"
             */
            if (clickTime > 1) {
                if (clickTime >= 1200) {
                    if ($('#e' + clickTime)) {
                        $('#e' + clickTime).click();
                    }
                } else {
                    if ($('#m' + clickTime)) {
                        $('#m' + clickTime).click();
                    }
                }
            }

        });
        return true;
    },

    /**
     * This gets the state model that we're using, and checks each package to make sure that every one has a staff name
     * and id assigned to it.
     *
     * @returns {boolean}
     */
    hasPackagesWithNoStaff: function () {
        var that = this;

        var packagesWithNoStaff = false;

        /* If the services in the state model has some models in it, then we're populating this, so this is what we
         * want to check against, otherwise we call getServiceDetails. This if statements prevents us getting into the
         * state of using multiple models and assigning staff in on model, and checking for assigned staff in the other.
         */
        var serviceDetails = that.stateModel.getServiceDetails(that.servicesAll, that.staffCollection);
        _.each(serviceDetails, function (service) {
            if (!packagesWithNoStaff && service.serviceType === 'package') {
                _.each(service.packageDetails, function (subPackage) {
                    if(subPackage.type === 'service' && (subPackage.staffId === -1 || subPackage.staffId === NO_STAFF_SELECTED)) {
                        packagesWithNoStaff = true;
                    }
                });
            }
        });
        return packagesWithNoStaff;
    },

    render: function () {
        var data,
            currentPage = $('.main.withProceeder').data('page');

        data = this.mergeTemplateData();
        data.timeRedoError = this.timeRedoError;

        /**
         * dont want to cache when coming back to this page because somebody else might have taken slot
         * or when user logs in
         */
        if (currentPage !== this.page || this.userModelId !== data.userModel.id) {
            this.availabilityCollection.resetDays();
            data.noBookingAvailable = this.noBookingAvailable;
            this.$el.html(this.template(data));
            BaseView.prototype.render(this);
            this.setPageTitle(this.pageTitle);
        }
        this.renderBookedServices();
        this.userModelId = data.userModel.id;
    }


});
