/**
 * Staff
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    StaffModel = require('./../models/staffModel'),
    Backbone = require('backbone');
var config = require('./../models/configModel');

Backbone.$ = $;

module.exports = Backbone.Collection.extend({
    model: StaffModel,
    servicesByStaff: false,
    businessToken: false,
    defaultComparator: 'seniority',  // default to most to least senior
    comparator: undefined,
    sortDirection: 'asc',

    initialize: function(models, options) {
        if (typeof options === 'undefined') {
            options = {};
        }

        this.setComparator(options.staffSortOrder);
    },

    setComparator: function (sortOrder)  {
        // valid sort orders taken from K1 OB settings table enum column ob_staff_sort
        var staffSortAlphabetically = 'alphabetically';
        var staffSortPosition = 'calendar position';
        var staffSortSeniorityASC = 'seniority asc'; // least senior to most. Seniority starts at 0 as most senior
        var staffSortSeniorityDESC = 'seniority desc'; // most senior to least

        if (typeof sortOrder !== 'undefined') {
            if (sortOrder === staffSortPosition) {
                this.comparator = 'position';
            } else if (sortOrder === staffSortSeniorityASC || sortOrder === staffSortSeniorityDESC) {
                this.comparator = 'seniority';
                if (sortOrder === staffSortSeniorityASC) {
                    // invert sort order when sorting by least to most senior, as seniority starts at 0 as most senior
                    this.sortDirection = 'desc';
                }
            } else if (sortOrder === staffSortAlphabetically) {
                this.comparator = 'formattedName';
            } else {
                this.comparator = this.defaultComparator;
            }
        } else {
            this.comparator = this.defaultComparator;
        }
    },

    url:
        function() {
            var bid = this.businessToken;
            /* istanbul ignore if*/
            if (!bid) {
                bid = config.getBusinessToken();
            }
            return config.getAPIUrl() + 'staff/' + bid;
    },

    sortStaff: function(staffArray) {
        var comparator = this.comparator ? this.comparator : this.defaultComparator;
        var sortDirection = this.sortDirection;
        staffArray.sort(function(staffA, staffB){
            if (typeof comparator === 'undefined') { // feature disabled, leave the sort order in the current broken default order
                return 0;
            }
            var sortOutput = 0;
            if (typeof staffA[comparator] === 'undefined') {
                staffA[comparator] = 0;
            }
            if (typeof staffB[comparator] === 'undefined') {
                staffB[comparator] = 0;
            }
            if (staffA[comparator] === staffB[comparator]) {
                sortOutput = 0;
            } else {
                if (typeof staffA[comparator] === 'string') {
                    sortOutput = staffA[comparator].localeCompare(staffB[comparator]);
                } else {
                    sortOutput = staffA[comparator] - staffB[comparator];
                }
            }
            if (sortDirection === 'desc') { // invert sort if we're sorting descending instead of ascending
                sortOutput *= -1;
            }
            // sort alphabetically if the order is still zero and we haven't already sorted by the same
            // this is intentionally after inverting for descending order, as we do not want to invert this backup alphabetic sort
            if (sortOutput === 0 && comparator !== 'formattedName') {
                sortOutput = staffA.formattedName.localeCompare(staffB.formattedName);
            }

            return sortOutput;
        }, comparator, sortDirection);

        return staffArray;
    },

    getCapability: function(services) {
        var that = this;

        return $.get(this.url() + "/services/" + services.join("-"), function(result) {
            _.each(result, function(serviceDetails) {
                _.each(serviceDetails, function(staff) {
                    var thisStaff = that.get(staff.staffId);
                    staff.firstName = thisStaff.get('firstName');
                    staff.lastName = thisStaff.get('lastName');
                    staff.formattedName = thisStaff.get('formattedName');
                    if (typeof thisStaff.get('position') !== 'undefined') {
                        staff.position = parseInt(thisStaff.get('position'));
                    }
                    if (typeof thisStaff.get('seniority') !== 'undefined') {
                        staff.seniority = parseInt(thisStaff.get('seniority'));
                    }
                });
            });

            that.servicesByStaff = result;
            _.each(that.servicesByStaff, function(serviceDetails) {
                serviceDetails = that.sortStaff(serviceDetails);
            });
        });
    },

    findByKid: function (kid) {
        return this.findWhere({'id': kid});
    },

    /**
     * Creates a special staff model to represent a user selection of "anyone".
     * @returns {StaffModel}
     */
    createAnyoneStaff: function () {
        return {
            'firstName': '(anyone)',
            'formattedName': '(anyone)',
            'lastName': '',
            'staffId': '0',
            'staffName': '(anyone)'
        };
    }

});
