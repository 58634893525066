/**
 * Bookings Page
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    config = require('./../models/configModel'),
    templatePage = Handlebars.compile(require('./../../templates/pageClient.hbs').default),
    partialLoyaltyBalance = Handlebars.compile(require('./../../templates/partialLoyaltyBalance.hbs').default),
    partialConcessionBalances = Handlebars.compile(require('./../../templates/partialConcessionBalances.hbs').default),
    partialCentralVoucherBalances = Handlebars.compile(require('./../../templates/partialCentralVoucherBalances.hbs').default),
    BookingModel = require('./../models/bookingModel'),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    BaseView = require('./baseView'),
    ServicesCollection = require('./../collections/servicesCollection'),
    PackagesCollection = require('./../collections/packagesCollection'),
    StaffCollection = require('./../collections/staffCollection'),
    Service = require('./../models/serviceModel'),
    loading = require('./../helpers/loadingScreen'),
    moment = require('moment'),
    PhotoEventsView = require('./../views/photoEventsView');

Backbone.$ = $;
require('jquery-ui');
require('bootstrap'); // for unit testing
require('bootstrap-growl');

var myBookingsTemplate = Handlebars.compile(require('./../../templates/myBookingsTab.hbs').default),
    myDetailsTemplate = Handlebars.compile(require('./../../templates/myDetailsTab.hbs').default),
    myPhotosTemplate = Handlebars.compile(require('./../../templates/myPhotosTab.hbs').default);

Handlebars.registerPartial('myBookings', myBookingsTemplate);
Handlebars.registerPartial('myDetails', myDetailsTemplate);
Handlebars.registerPartial('myPhotos', myPhotosTemplate);

module.exports = BaseView.extend({
    template: templatePage,
    page: 'myProfile',
    pageTitle: 'My profile',
    pastAppts: false,
    futureAppts: false,
    branchesModel: false,
    cancellationHoursSetting: 24,
    pastLimit: 10,
    futureLimit: 10,
    photoEventsView: null,

    events: {
        'click .rebookBtn': 'rebook',
        'click .rescheduleBtn': 'reschedule',
        'click #cancelSubmit': 'cancelConfirmed',
        'click #tab_photos, #tab_details, #tab_bookings': 'switchTab',
        'click #details a': 'handleEditDetails',
        'click #cancelAppointmentButton': 'showCancelAppointmentModal',
        'hide.bs.modal #cancelAppointmentModal': 'cancelAppointmentHideEvent',
        'change .photoArea input:file' : 'handlePhotoUpload',
        'dragenter .fileDragZone': 'handleDragEnter',
        'dragover .fileDragZone': 'handleDragOver', //just to prevent default behaviour
        'dragleave .fileDragZone': 'handleDragLeave',
        'drop .fileDragZone': 'handlePhotoDrop',
        'click .pswp__button--delete': 'handlePhotoDelete'
    },

    initialize: function (options) {
        //call parent constructor
        BaseView.prototype.initialize(options);

        if (options.stateModel) {
            this.stateModel = options.stateModel;
        }
        if (options.userModel) {
            this.userModel = options.userModel;
        }
        if (options.businessModel) {
            this.businessModel = options.businessModel;
        }
        if (options.branchesModel) {
            this.branchesModel = options.branchesModel;
        }
        if (options.futureAppts) {
            this.futureAppts = options.futureAppts;
        }
        if (options.pastAppts) {
            this.pastAppts = options.pastAppts;
        }

        this.photoEventsView = new PhotoEventsView({userModel: this.userModel}); //todo get userModel into photoEventsView
    },

    /**
     * Renders page and sets tab as active but loading. Assumes that this is called before any data fetch has returned.
     * @param tabId
     */
    render: function (tabId) {
        if (tabId === undefined) {
            tabId = "bookings";
        }

        var data = this.mergeTemplateData();

        //dont re-render the page if we are just changing the hash.
        var page = this.getPageName(tabId);
        if (this.$el.find('.container.main').data('page') !== data[page]
                || $('.container.main').data('page') !== data[page]
                || this.cachedUser !== this.userModel.get('id') + '-' + this.userModel.get('business_id')
                || this.userModel.get('bustCache') === true)
        {
            this._doRender(data, tabId);
            this.cachedUser = this.userModel.get('id') + '-' + this.userModel.get('business_id');
        }

        this.photoEventsView.hideFileInputIfNotSupported(false);

        this.markTabActive(tabId);
    },

    /**
     * Renders the tab content. Assumes that this is called after fetch returns data.
     * @param tab
     */
    renderTab: function(tab) {
        var data = this.mergeTemplateData(), content;

        switch(tab) {
            case "bookings":
                data.futureAppts = this.renderAppointments(this.userModel.get('futureAppointments')).slice(0, this.futureLimit);
                data.pastAppts = this.renderAppointments(this.userModel.get('pastAppointments')).slice(0, this.pastLimit);
                var allAppts = data.futureAppts.concat(data.pastAppts);
                data.hasAppts = (allAppts.length > 0) ? true : false;
                content = myBookingsTemplate(data);
                $("#bookings").html(content);
                break;
            case "details":
                this.formatPhoneNumber(data.userModel.phone, formattedPhone => {
                    data.userModel.formattedPhone = formattedPhone;
                    content = myDetailsTemplate(data);
                    $("#details").html(content);
                });
                break;
            case "photos":
                data = _.extend(data, {attachments_photo: this.userModel.get('attachments_photo').toJSON()});
                content = myPhotosTemplate(data);
                $("#photos").html(content);
                this.photoEventsView.hideFileInputIfNotSupported(false);
                this.photoEventsView.initPhotoSwipeFromDOM(this.$el.find(".my-gallery"));
                break;
        }

        this.markTabActive(tab);
    },


    /**
     *
     * @param branchModel (branchModel or businessModel, basically a backbone model with email and phone attributes)
     * @returns {string}
     *
     * this is very verbose as a hbs template, so it is done in normal js.
     * It is also being conditionally injected into a modal at runtime
     */
    getContactInfoSection: function(branchModel) {
        function has(val){
            return typeof val !== 'undefined' && val.trim() !== '';
        }

        var hasPhone = has(branchModel.get('phone'));
        var hasEmail = has(branchModel.get('email'));

        var contactInfoSection = '';
        if(hasPhone){
            contactInfoSection += ' on ' + _.escape(branchModel.get('phone'));
        }
        if(hasPhone && hasEmail){
            contactInfoSection += ' or';
        }
        if(hasEmail){
            contactInfoSection += ' at ' + '<a href="mailto:' + _.escape(branchModel.get('email')) + '">' + _.escape(branchModel.get('email')) + '</a>';
        }

        return contactInfoSection + '.';

    },

    switchTab: function(e) {
        e.preventDefault();
        var tabId = $(e.target).closest('li').attr('id');
        tabId = tabId.replace("tab_", "");
        var hash = this.getTabUrl(tabId);
        window.location.hash = hash;
    },

    getTabUrl: function(tabId) {
        var page = "#"+this.getPageName(tabId);
        return page;
    },

    getPageName: function(tabId) {
      return "myProfile/"+tabId;
    },

    getTabName: function(tabId) {
        return "tab_"+tabId;
    },

    getTitleName: function(tabId) {
      return "My Profile";
    },

    markTabActive: function(tabId) {
        var name = this.$el.find('#' + tabId);
        var nameTab = this.$el.find('#'+this.getTabName(tabId));

        $('#bookings, #details, #photos').hide();

        if(!name.hasClass('active')) {
            $('.tab-content .active').removeClass('active');
            name.addClass('active');
        }
        if(!nameTab.hasClass('active')) {
            $('#accountTabs .active').removeClass('active');
            nameTab.addClass('active');
        }

        $(name).show();
    },

    _doRender: function(data, tabId) {

        this.$el.html(this.template(data));
        BaseView.prototype.render(this);

        this.setPageTitle(this.getTitleName(tabId));
    },

    /**
     *
     * @param appointments
     * @returns {*|Array}
     */
    renderAppointments: function(appointments) {
        var that = this;
        return _.map(appointments, function(appt, key) {
            return {
                'bookingId':appt.bookingId, //for the rebook, reschedule and cancel options
                'branch':appt.branchName, //need to get branch location information
                'date':that._get_date(appt.startDate), //format the appointment start date
                'address':that._get_address(appt.businessId), //need to get branch location information
                'services':that._get_services(appt.services), //[{serviceName} (with <staff>)], [{serviceName} (with <staff>)]
                'paymentAmount':appt.paymentAmountFormatted,
                'paymentStatus':that.transformPaymentStatus(appt.paymentStatus),
                'cancellable': that._is_cancellable(appt.startDate, appt.businessId),
                'can_reschedule': that._is_outside_cancellationHours(appt.startDate, appt.businessId),
                'can_rebook': appt.branchValid,
                'status': appt.status
            };
        });
    },

    transformPaymentStatus: function(paymentStatus) {
        switch(paymentStatus) {
            case 'paid':
            case 'invoiced':
                return 'paid';
            case 'pending_refund':
            case 'refund':
                return 'refunded';
            default:
                return null;
        }
    },
    /**
     *
     * @param date
     * @param businessId
     * @returns {boolean}
     * @private
     */
    _is_cancellable: function(date, businessId) {
        var business = this.businessModel, businessAllowCancel;
        if(this.businessModel.get('id') === businessId) {
            businessAllowCancel = this.businessModel.get('cancellations_enabled');
        } else {
            business = this.branchesModel.get(businessId);
            if(business){
                businessAllowCancel = business.get('cancellations_enabled');
            }
        }

        if(!businessAllowCancel) {
            return false;
        }
        return this._is_outside_cancellationHours(date, businessId);
    },

    _is_outside_cancellationHours: function(date, businessId) {
        var currentDateTime = moment(),
            scheduleDate = moment(date),
            duration = moment.duration(scheduleDate.diff(currentDateTime)),
            business = this.businessModel,
            cancelPeriod = this.cancellationHoursSetting;

        if(this.businessModel.get('id') !== businessId) {
            business = this.branchesModel.get(businessId);
        }

        if (business) {
            cancelPeriod = Number(business.get('cancel_period')) > 0 ? Number(business.get('cancel_period')) : this.cancellationHoursSetting;
        }

        return duration.asHours() >= cancelPeriod;
    },

    /**
     *
     * @param date
     * @returns {*}
     * @private
     */
    _get_date: function(date) {
        return moment(date).format("dddd D MMMM [at] h:mma");
    },

    /**
     *
     * @param businessId
     * @returns {string}
     * @private
     */
    _get_address: function(businessId) {
        var address = "";
        _.each(this.branchesModel.models, function(branch, key) {
            if (businessId === branch.attributes.id) {
                address = branch.attributes.address;
            }
        });
        return address;
    },

    /**
     *
     * @param businessId
     * @returns {string}
     * @private
     */
    _get_online_booking_address: function(businessId) {
        var obAddress = "";
        if (this.businessModel.is_boutique()) {
            obAddress = this.businessModel.get("online_booking_address");
        }
        else {
            _.each(this.branchesModel.models, function(branch, key) {
                if (businessId === branch.attributes.id) {
                    obAddress = branch.attributes.online_booking_address;
                }
            });
        }
        return obAddress;
    },

    /**
     *
     * @param services
     * @returns {Array}
     * @private
     */
    _get_services: function(services) {
        var formattedServices = [];

        _.each(services, function(service, key){
            var packageData, packageItemData;
            if (service.packageName === null) {
                var data = {
                    'type':"service",
                    'name':service.serviceName,
                    'serviceId':service.serviceId,
                    'staff':service.staffName,
                    'staffId':service.staffId
                };
                formattedServices.push(data);
            }
            else {
                if (!service.staffId) {
                    packageItemData = {
                        'serviceName': service.productName,
                        'packageItemId': service.packageItemId
                    };
                }
                else {
                    packageItemData = {
                        'serviceName': service.serviceName,
                        'serviceId': service.serviceId,
                        'packageItemId': service.packageItemId,
                        'staff': service.staffName,
                        'staffId': service.staffId
                    };
                }

                var index = _.findIndex(formattedServices, {'name':service.packageName});
                if (index === -1) {
                    //didn't find package
                    packageData = {
                        'type': "package",
                        'name': service.packageName,
                        'serviceList': [packageItemData]
                    };
                    formattedServices.push(packageData);
                }
                else {
                    //found package
                    packageData = formattedServices[index];
                    packageData.serviceList.push(packageItemData);
                    formattedServices[index] = packageData;
                }
            }
        });

        return formattedServices;
    },

    /**
     *
     * @param e
     */
    reschedule: function(e) {
        var target = e.currentTarget;
        var bookingId = $(target).data("id");
        var booking = this._get_appointment_by_booking_id(bookingId, "all");

        if (!booking) {
            return false;
        }
        this.bookingChangeWithDepositCheck(booking, e, goToRescheduleScreen, [booking, bookingId]);

        function goToRescheduleScreen(booking, bookingId) {
            loading.until(this._get_collection_data(booking.businessId)).done(_.partial(this.prepareNewBookingFromExisting, this, booking, bookingId));
        }
    },

    /**
     *
     * @param booking
     * @param e
     * @param canChangeFunction
     * @param canChangeArgs
     *
     * Shows a warning modal with contact information if the booking has a deposit on it, otherwise calls the provided
     * callback function
     */
    bookingChangeWithDepositCheck: function (booking, e, canChangeFunction, canChangeArgs){
        if(this.userModel.bookingHasDeposit(booking) === true){
            e.preventDefault();
            var branchModel = this.findBranchOrBusinessModel(booking.businessId);
            this.showPrepayChangeWarning(branchModel);
        }
        else {
            canChangeFunction.apply(this, canChangeArgs);
        }
    },

    findBranchOrBusinessModel: function(branchId){
        branchId += ''; //convert to string
        if(this.businessModel.id === branchId){
            return this.businessModel;
        }
        else{
            return _.find(this.branchesModel.models, function(branch){
               return branch.get('id') === branchId;
            });
        }
    },

    /**
     *
     * @param e
     */
    rebook: function(e) {
        var target = e.currentTarget;
        var bookingId = $(target).data("id");
        var booking = this._get_appointment_by_booking_id(bookingId, "past");
        this.stateModel.is_rebooking = true;
        if (!booking) {
            return false;
        }
        loading.until(this._get_collection_data(booking.businessId)).done(_.partial(this.prepareNewBookingFromExisting, this, booking, undefined));
    },

    /**
     *
     * @param booking
     */
    prepareNewBookingFromExisting: function(that, booking, reschedule_flag) {
        var bookingItems = that._get_services(booking.services);
        var serviceIdList = that._get_service_id_list(booking.services);
        var businessAddress = that._get_online_booking_address(booking.businessId);
        var capableStaffPromise = that.staffCollection.getCapability(serviceIdList);
        var pageRedirect = "#time";
        var rebook = [], unavailableRebook = [], formattedRebook = [];

        loading.until(capableStaffPromise).done(function(data){
            //find unavailable services / staff
            _.each(bookingItems, function(item){
                if (item.type === Service.PACKAGE) {
                    if (that._is_package_bookable(item)) {
                        _.each(item.serviceList, function(packageItem, key){
                            if (packageItem.staffId && !that._is_staff_bookable(packageItem)) {
                                item.serviceList[key].staff = that.businessModel.getDefaultStaffPersonName();
                                item.serviceList[key].staffId = that.businessModel.getDefaultStaffPerson();
                            }
                        });
                        rebook.push(item);
                    }
                    else {
                        unavailableRebook.push(item);
                    }
                }
                else if (item.type === Service.SERVICE) {
                    if (that._is_service_bookable(item)) {
                        if (!that._is_staff_bookable(item)) {
                            item.staff = that.businessModel.getDefaultStaffPersonName();
                            item.staffId = that.businessModel.getDefaultStaffPerson();
                        }
                        rebook.push(item);
                    }
                    else {
                        unavailableRebook.push(item);
                    }
                }
            });

            //get data formatted for stateModel to interpret, with a few extra values
            rebook.forEach(function(item) {
                if (item.type === Service.PACKAGE) {
                    var package_ = that._get_package_from_collection(item.name);
                    package_.get('services').forEach(function(service, index) {
                        service.staffId = service.staffId || item.serviceList[index].staffId;
                    });
                    formattedRebook.push(package_);
                }
                else if (item.type === Service.SERVICE) {
                    var service = that._get_service_from_collection(item.serviceId);
                    service.attributes.staffId = item.staffId;
                    formattedRebook.push(service);
                }
            });

            if (unavailableRebook.length > 0) {
                pageRedirect = "#services/all";
            }

            that._set_collection_on_state_model();
            var url = that.stateModel.prepareMyBookingsRedirect(pageRedirect, businessAddress, formattedRebook, unavailableRebook, reschedule_flag);
            console.log("redirecting to " + url);
            window.location.replace(url);
        });
    },

    /**
     *
     * @param services
     * @returns {Array}
     * @private
     */
    _get_service_id_list: function(services) {
        var serviceList = [];
        _.each(services, function(service, index){
            if (service.serviceId) {
                serviceList.push(service.serviceId);
            }
        });
        return serviceList;
    },

    /**
     *
     * @param services
     * @private
     */
    _is_service_bookable: function(service) {
        var serviceId = service.serviceId;
        var foundService = this._get_service_from_collection(serviceId);
        if (!foundService || typeof foundService === 'undefined') {
            return false;
        }
        return true;
    },

    /**
     *
     * @param serviceId
     * @returns {*}
     * @private
     */
    _get_service_from_collection: function(serviceId) {
        return this.servicesCollection.get(serviceId);
    },

    /**
     *
     * @param package
     * @private
     */
    _is_package_bookable: function(_package) {
        var packageName = _package.name;
        var foundPackage = this._get_package_from_collection(packageName);
        if (!foundPackage || typeof foundPackage === 'undefined') {
            return false;
        }
        return true;
    },

    /**
     *
     * @param packageName
     * @returns {*}
     * @private
     */
    _get_package_from_collection: function(packageName) {
        return this.packagesCollection.getPackageByName(packageName);
    },

    /**
     *
     * @param services
     * @private
     */
    _is_staff_bookable: function(service) {
        var that = this;
        var staffId = service.staffId;
        var foundStaff = this._get_staff_from_collection(staffId);
        var capableStaffList = that.staffCollection.servicesByStaff[service.serviceId];
        var staffIsCapable = false;
        var canBeBooked = false;

        _.each(capableStaffList, function(staff, index){
            if (service.staffId === staff.staffId) {
                staffIsCapable = true;
            }
        });
        if ((foundStaff && staffIsCapable)
            && (typeof foundStaff !== 'undefined' && typeof staffIsCapable !== 'undefined'))
        {
            canBeBooked = true;
        }

        return canBeBooked;
    },

    /**
     *
     * @param staffId
     * @returns {*}
     * @private
     */
    _get_staff_from_collection: function(staffId) {
        return this.staffCollection.get(staffId);
    },

    /**
     * Needs to be here so that we can change business depending on branch.
     * @param businessToken
     * @private
     */
    _get_collection_data: function(businessToken) {
        if (!this.servicesCollection || this.servicesCollection.businessToken !== businessToken) {
            this.servicesCollection = new ServicesCollection();
            this.servicesCollection.businessToken = businessToken;
            this.servicesPromise = this.servicesCollection.fetch();
        }
        if (!this.staffCollection || this.staffCollection.businessToken !== businessToken) {
            var staffCollectionOps = {};
            if (typeof this.businessModel.get('ob_staff_sort') !== 'undefined') {
                staffCollectionOps = {staffSortOrder: this.businessModel.get('ob_staff_sort')};
            }
            this.staffCollection = new StaffCollection(null, staffCollectionOps);
            this.staffCollection.businessToken = businessToken;
            this.staffPromise = this.staffCollection.fetch();
        }
        if (!this.packagesCollection || this.packagesCollection.businessToken !== businessToken) {
            this.packagesCollection = new PackagesCollection();
            this.packagesCollection.businessToken = businessToken;
            this.packagesPromise = this.packagesCollection.fetch();
        }

        return loading.until(this.servicesPromise, this.staffPromise, this.packagesPromise);
    },

    /**
     *
     * @private
     */
    _set_collection_on_state_model: function() {
        if(this.servicesCollection !== undefined) {
            this.stateModel.servicesCollection = this.servicesCollection;
        }
        if(this.packagesCollection !== undefined) {
            this.stateModel.packagesCollection = this.packagesCollection;
        }
        if(this.staffCollection !== undefined) {
            this.stateModel.staffCollection = this.staffCollection;
        }
    },

    /**
     *
     * @param e
     */
    showCancelAppointmentModal: function(e) {
        e.preventDefault();
        var button = $(e.target);
        var bookingId = button.data('id');
        var scheduleDate = button.data('schedule');

        var booking = this._get_appointment_by_booking_id(bookingId, "future");
        this.bookingChangeWithDepositCheck(booking, e, showCancelModal, [bookingId, scheduleDate]);

        function showCancelModal(bookingId, scheduleDate) {
            var cancelModal = $('#cancelAppointmentModal');
            cancelModal.find('.appointment_date').text(scheduleDate);
            cancelModal.find('.bookingId').val(bookingId);
            cancelModal.modal('show');
        }
    },

    showPrepayChangeWarning: function(branchModel) {
        var prepayChangeModal = $('#prepayChangeModal');
        prepayChangeModal.find('#prepayChangeContactInfo').html(this.getContactInfoSection(branchModel));
        prepayChangeModal.modal('show');
    },

    /**
     *
     */
    cancelAppointmentHideEvent: function() {
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $('#cancelAppointmentValidationAnchor').hide();
    },

    /**
     *
     * @param e
     * @returns {boolean}
     */
    cancelConfirmed: function(e) {
        var that = this;
        var notify;
        var data = {};
        var formData =  $(e.currentTarget).closest('#cancelAppointmentModal').find('form').serializeArray();

        _.each(formData, function(element){
            data[element.name] = element.value;
        });

        if(data.cancelReason === ''){
            $('#cancelAppointmentValidationAnchor').html('');
            $('#cancelAppointmentValidationAnchor').append('<li>Please provide a reason for cancelling.</li>');
            $('#cancelAppointmentValidationAnchor').show();
            return false;
        }

        notify = $.notify('<strong>Cancelling</strong> your booking', { allow_dismiss: false });
        var appts = this.userModel.get('futureAppointments');

        var booking = _.findWhere(appts, { bookingId : data.bookingId });

        if(booking) {
            var bookingModel = new BookingModel(booking);
            //bookingModel.prepareCancel();
            //bookingModel.appendCancelNotes(data.cancelReason);

            $('#bookings .tabContent').hide();
            $('#bookings .tabLoading').show();
            loading.showLoadingScreen();

            bookingModel.set('cancelReason', data.cancelReason);
            bookingModel.cancel().then(function(){
                //return the promise we want to run next, the data from this promise get passed into the next done call
                return that.userModel.fetchFutureAppointments(10, that.userModel.get('business_id'),true);
            }).done(function(futureAppts){
                that.userModel.set('futureAppointments', futureAppts);

                notify.update({
                    type: 'success',
                    message: '<i class="fa fa-check"></i> Your booking has been cancelled'
                });

                that.renderTab("bookings");

                $('#bookings .tabContent').show();
                $('#bookings .tabLoading').hide();
                loading.hideLoadingScreen();
            });

            analytics.trackGAEvent(true,'Bookings','Cancelled appointment');
        }

        that.$el.find('#cancelAppointmentModal').modal('hide');
    },

    /**
     *
     * @param bookingId
     * @param period
     * @returns {boolean}
     * @private
     */
    _get_appointment_by_booking_id: function(bookingId, period) {
        var appointment = false;
        var bookings = {};
        switch (period) {
            case "future":
                bookings = this.userModel.get('futureAppointments');
                break;
            case "past":
                bookings = this.userModel.get('pastAppointments');
                break;
            default: //"all" future + past
                bookings = this.userModel.get('futureAppointments').concat(this.userModel.get('pastAppointments'));
        }
        var appts = bookings;
        _.each(appts, function(appt) {
            if (bookingId && appt.bookingId.toString() === bookingId.toString()) {
                appointment = appt;
            }
        });
        return appointment;
    },

    handleEditDetails: function(e) {
        e.preventDefault();
        this.showEditDetailsModal();
    },

    handlePhotoUpload: function(ev, callback) {
        this.photoEventsView.handlePhotoUploadEvent(ev, callback);
    },

    handleDragEnter: function(ev, callback) {
        this.photoEventsView.handleDragEnterEvent(ev, callback);
    },

    handleDragOver: function(ev, callback) {
        this.photoEventsView.handleDragOverEvent(ev, callback);
    },

    handleDragLeave: function(ev, callback) {
        this.photoEventsView.handleDragLeaveEvent(ev, callback);
    },

    handlePhotoDrop: function(ev, callback) {
        this.photoEventsView.handlePhotoDropEvent(ev, callback);
    },

    handlePhotoDelete: function(ev, callback) {
        this.photoEventsView.handlePhotoDeleteEvent(ev, callback);
    }
});
